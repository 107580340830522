import React from 'react';
import PropTypes from 'prop-types';

import { Dropdown, Icon } from 'semantic-ui-react';
import css from 'css/LoginForm.css';

/**
 * The ChronoxDropdown component can be used in order to
 * create a selectable list that is easily handled by a simple dropdown.
 *
 * PROPS:
 *   - placeholder: the text that will be displayed when no item is chosen.
 *
 *   - disabled: should be set to true if the dropdown should be inaccessible, but not hidden
 *
 *   - hidden: should be set to true if the dropdown is completely hidden
 *
 *   - options: an array of objects in the following form ->
 *      {
 *        text: "the displayed text of the option",
 *        value: " the hidden value assigned to the option"
 *      }
 *
 *   - onChange: accepts a callback function that is called whenever the dropdown changes.
 *     The arguments for the function should be -> (event, {text, value})
 *
 *   - default value
 *
 * */
class ChronoxDropdown extends React.Component {
  render() {
    let element = <div />;
    const spanWithIcon = <span><Icon name={this.props.icon} /> {this.props.placeholder} </span>;
    // If the dropdown is completely hidden, the empty div will be rendered
    if (!this.props.hidden) {
      // If the dropdown is not disabled
      if (!this.props.disabled) {
        element = (
          <Dropdown
            className={`${css.chronoxInput} selection`}
            floating
            fluid
            trigger={spanWithIcon}
            options={this.props.options}
            onChange={this.props.onChange}
          />
        );
      // If the dropdown is disabled, render a different (but similar) element
      } else {
        element = <Dropdown className={css.chronoxInput} placeholder={this.props.placeholder} icon={this.props.icon} iconPosition="left" disabled={this.props.disabled} fluid selection options={this.props.options} />;
      }
    }

    return (element);
  }
}

ChronoxDropdown.propTypes = {
  hidden: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  onChange: PropTypes.func.isRequired,
};

ChronoxDropdown.defaultProps = {
  disabled: false,
};

export default ChronoxDropdown;
