import React from 'react';

import { Grid } from 'semantic-ui-react';

import css from 'css/ChronoxBox.css';

/**
 * This component should be used to wrap the content that is put inside of a ChronoxBox instance.
 * It puts all of the content inside of a row, which is put inside of the box.
 * This will position the content in the center, and ensure that the space between each row is enough.
 */
class ChronoxBoxRow extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        /* The element that will be rendered. It is composed of a row with a column inside of it. The column width (12/16) determines the width of the content. */
        const element = (
            <Grid.Row><Grid.Column className={css.column}>{this.props.children}</Grid.Column></Grid.Row>
        );

        return (element);
    }
}

export default ChronoxBoxRow;
