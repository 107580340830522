import React from 'react';
import PropTypes from 'prop-types';
import OldView from 'Toolkit/OldView.jsx';

class PageWorkreport extends React.Component {
  render() {
    const { order } = this.props.match.params;
    const oldViewElement = <OldView mode="view" order={order} refresh={false} />;

    return oldViewElement;
  }
}

PageWorkreport.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      order: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default PageWorkreport;
