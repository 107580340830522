/* eslint import/no-unresolved: off, import/extensions: off */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TransitionablePortal, Message } from 'semantic-ui-react';
import { translate } from 'react-i18next';

import LoginWindow from 'Container/LoginWindow';
import { Chronox } from 'Toolkit/Logo';
import { LanguageChooser } from 'Toolkit/Utils/LanguageChooser';
import ChronoxBox from 'Toolkit/ChronoxBox/ChronoxBox';
import View from 'Toolkit/View';
import i18n from 'i18n';
import styles from 'css/login.css';
import { LOGIN_FAILURE } from '../Actions/constants';

function handleLanguageChange(language) {
  i18n.changeLanguage(language);
}

/**
 * This component is dependent on the i18n instance,
 * and needs webpack (or similar build environment) to create an alias for i18n, since
 * it imports the instance like the following -> import i18n from 'i18n'.
 * */
class ScreenSignIn extends React.Component {
  render() {
    const { t, errorMessageOpen } = this.props;
    const currentLanguage = i18n.language;

    const languageChooserElement = (
      <LanguageChooser options={[{ countryCode: 'se', languageCode: 'sv' }, { countryCode: 'gb', languageCode: 'en' }]} value={currentLanguage} onChange={handleLanguageChange} />
    );

    const element = (
      <View id="container" style={styles.loginContainer}>
        <TransitionablePortal open={errorMessageOpen} transition={{ animation: 'fade down', duration: 500 }}>
          <Message
            style={{
              position: 'fixed',
              top: '10px',
              left: 0,
              right: 0,
              marginLeft: 'auto',
              marginRight: 'auto',
              zIndex: 1000,
              width: '30%',
              minWidth: '300px',
            }}
            negative
          >
            <p>{t('wrongAuthenticationDetails')}</p>
          </Message>
        </TransitionablePortal>
        <ChronoxBox full>
          <ChronoxBox.Row>
            <Chronox style={{ bottom: 0 }} size="medium" />
          </ChronoxBox.Row>
          <ChronoxBox.Row>
            <LoginWindow />
          </ChronoxBox.Row>
          <ChronoxBox.Row>
            {/* Only show the language chooser element in development mode */}
            {i18n.shouldChooseLanguage() ? languageChooserElement : null}
            <p style={{ color: 'white' }}>{t('webbased')}, <a href="//www.chronox.se/">{t('readmore')}.</a></p>
          </ChronoxBox.Row>
        </ChronoxBox>
      </View>
    );

    return element;
  }
}

ScreenSignIn.defaultProps = {
  errorMessageOpen: false,
};

ScreenSignIn.propTypes = {
  t: PropTypes.func.isRequired,
  errorMessageOpen: PropTypes.bool,
};

// Maps the state of redux to the props of the component
const mapStateToProps = state => Object.assign({}, {
  errorMessageOpen: state.currentErrors.filter(error => error.type === LOGIN_FAILURE).length > 0,
});

// Connect component to redux
const ScreenSignInConnected = connect(mapStateToProps)(ScreenSignIn);

export default translate()(ScreenSignInConnected);
