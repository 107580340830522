import React from 'react';

import List from 'Toolkit/List.jsx';

class LedgerIndexTable extends React.Component {
	render() 
	{
		const headings = ["Arbetsplats", "Identifikationsnr", "Typ", "Startdatum", "Slutdatum", "Verksamm nu / totalt"];
        //const classes = "LedgerIndexTable";
        //const id = "Chronox_Client_AdminWeb_Block_LedgerIndexTable1";
		let rowsList = [["Inga aktiva personalliggare", "", "", "", "", ""]];
		
		if(!this.props.error) {
			if(this.props.rows.length > 0)
				rowsList = this.props.rows.map((row, index) =>{
					var rowLastLogTime = "-";
					var url = app.linkPrefix + "ledgers/" + row.id;
					if(row.mode == "byggherre") {
						url += "/grouped";
					} 
					if(!row.active)
						rowLastLogTime = row.lastLogTime;
					return [<a href={url} key={row.id}>{row.name}</a>, row.locationAccessCode, row.mode, row.firstLogTime.substring(0,10), row.lastLogTime.substring(0,10), row.workersCheckedInCount + " / " + row.participantsCount]
				});
		}
		else
			rowsList = [["Någonting gick fel", "", "", "", "", ""]];

		return (
			// style={{'overflowX': 'auto'}}
			<div style={{'overflowX': 'auto'}}>
				<List items={rowsList} headings={headings} className="table-ledgers" />
			</div>
		);
	}
}

export default LedgerIndexTable;