import React from 'react';

import { Tab } from 'semantic-ui-react';
import { connect } from 'react-redux';

/**
 * The following structure (for example) should be used when creating an instance of this component:
 *  <Tab titles={["Title1", "Title2"]}><Component1 /><Component2 /></Tab>
 * */
class ChronoxTab extends React.Component {
    render() {
        let styles = {};
        if(this.props.minHeight) {
            styles.minHeight = this.props.minHeight;
        }
        
        const panes = this.props.children.map(
            (child, index) => {return {menuItem: "" + this.props.titles[index], render: () => <Tab.Pane><div style={styles}>{child}</div></Tab.Pane>}}
        );

        return <Tab panes={panes} defaultActiveIndex={this.props.activeTab} onTabChange={this.props.onTabChange} />;
    }
}

const mapStateToProps = state => {
    return {
        activeTab: state.loginBox.selectedTab,
    }
};

/*
const mapDispatchToProps = dispatch  => {
    return {
        onTabChange: (event, data) => {
            dispatch(setLoginBoxTab(data.activeIndex))
        }
    }
};*/

const TabContainer = connect(
    mapStateToProps,
    //mapDispatchToProps,
)(ChronoxTab);
    
export default TabContainer;