import React from 'react';
import { translate } from 'react-i18next';

import ProjectListCurrent from 'App/ProjectListCurrent.jsx'; // The default export is actually called App_ProjectListCurrent. Since it is the default export, you can name it anything here.
import Section from 'Toolkit/Section';
import AddProjectButton from 'App/AddProjectButton';

import style from 'css/CurrentProjectsSection.css';

class CurrentProjectsSection extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { t } = this.props;
        const element = (
            <Section title={t('CurrentProjectsSection.title')}>
                <ProjectListCurrent className={style.overFlowBox}></ProjectListCurrent>
                <br />
                <AddProjectButton></AddProjectButton>
            </Section>
        );

        return element;
    }
}

export default translate('Container')(CurrentProjectsSection);
