import React from 'react';
import {HTMLDocumentComponent} from "Toolkit/OldComponent";

/**
 * This component presents a list of all old projects, owned by the company of the logged in user.
 */
class App_ProjectListOld extends HTMLDocumentComponent { // Use an old component (for now), meaning that the server sends the markup
    componentNamespace()
    {
        return "Client.AdminWebV2.Components";
    }
}

export default App_ProjectListOld;
