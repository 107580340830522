import React from 'react';
import PropTypes from 'prop-types';
import StylePropType from 'react-style-proptype';
import { Image } from 'semantic-ui-react';

import logo from 'img/logo-chronox.png';

/**
 * The ChronoxLogo component is a reusable component, that contains the logo of the application.
 */
export default function ChronoxLogo(props) {
  const { size, style } = props;
  return (
    <Image className="no-print" src={logo} alt="Chronox" verticalAlign="middle" size={size} style={style} />
  );
}

ChronoxLogo.defaultProps = {
  size: 'small',
  style: {},
};

ChronoxLogo.propTypes = {
  size: PropTypes.string,
  style: StylePropType,
};
