import React from 'react';
import OldView from 'Toolkit/OldView.jsx';

class PageWorkOrderConfig extends React.Component {
    render() {
        const id = this.props.match.params.configId;

        return <OldView mode="settingsShowConfig" type="WorkOrder" id={id} refresh={false} />;
    }
}

export default PageWorkOrderConfig;
