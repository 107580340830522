import React from 'react';

import ChronoxMenu from './ChronoxMenu.jsx';

function ContentMenu(props) {
  const urls = app.store.getState().menu.map(item => Object.assign({}, item, { url: `/${(item.mode === 'index' ? '' : item.mode)}` }));
  return <ChronoxMenu items={urls} {...props} />;
}

export default ContentMenu;
