exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@charset \"UTF-8\";\r\n\r\n.gCZ8YhQM-JES1aXOsb9LT {\r\n}\r\n\r\n._2zMhPkrZQJyZsUBE0D1A1Y {\r\n}\r\n\r\n.uOwxr1aONnMLnP1grlf1y {\r\n  background: #fff6f6 !important;\r\n  color: #9f3a38 !important;\r\n}\r\n\r\n._340y8t3jdQyyokZkhpKtqd {\r\n  border: 1px solid #9f3a38 !important;\r\n}\r\n\r\n._2ADLcgY4Bsq39lg4IjG8yh {\r\n  background: #fffaf3 !important;\r\n  color: #573a08 !important;\r\n}\r\n\r\n._20tSf2W9-dnXyFMFqk_xg6 {\r\n  border: 0 0 0 1px #c9ba9b inset, 0 0 0 0 transparent !important;\r\n}\r\n", ""]);

// exports
exports.locals = {
	"infoDialogColor": "gCZ8YhQM-JES1aXOsb9LT",
	"infoDialogContainer": "_2zMhPkrZQJyZsUBE0D1A1Y",
	"errorDialogColor": "uOwxr1aONnMLnP1grlf1y",
	"errorDialogContainer": "_340y8t3jdQyyokZkhpKtqd",
	"warnDialogColor": "_2ADLcgY4Bsq39lg4IjG8yh",
	"warnDialogContainer": "_20tSf2W9-dnXyFMFqk_xg6"
};