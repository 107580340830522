exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2k1qCwLvi3S-Jyj0KwfRyd {\n    border: none;\n    margin-bottom: 3px;\n    margin-left: 8px;\n    font-weight: normal;\n    font-size: 1.1em;\n    margin-top: 2em;\n    color: #E88341;\n}\n\n._1yvxKaqQXKgMTOsgUAqLy3 {\n    border: none;\n    margin-bottom: 3px;\n    margin-left: 8px;\n    font-weight: normal;\n    font-size: 1.3em;\n    margin-top: 2em;\n    color: #E88341;\n}\n\n._3gfRf2fx5qOV9PSl8EtFaM {\n    border: none;\n    margin-bottom: 3px;\n    margin-left: 8px;\n    font-weight: normal;\n    font-size: 1.5em;\n    margin-top: 2em;\n    color: #E88341;\n}\n", ""]);

// exports
exports.locals = {
	"small": "_2k1qCwLvi3S-Jyj0KwfRyd",
	"medium": "_1yvxKaqQXKgMTOsgUAqLy3",
	"large": "_3gfRf2fx5qOV9PSl8EtFaM"
};