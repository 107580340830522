import React from 'react';
import OldView from 'Toolkit/OldView.jsx';

class PageEmployeePage extends React.Component {
    render() {
        const employee = this.props.match.params.employee;
        return <OldView mode="employeePage" employee={employee} refresh={false} />;
    }
}

export default PageEmployeePage;