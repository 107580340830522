exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@charset \"UTF-8\";\n\n/**\n * This css document contains the necessary style for the component Toolkit/LoginForm and Toolkit/LoginFormAdmin of the Chronox react ui toolkit v1.\n */\n._1l8LWfd8101LQ6yQNcPB3P {\n\tmargin-bottom: 1em;\n}\n._1l8LWfd8101LQ6yQNcPB3P input::-webkit-input-placeholder { /* Chrome/Opera/Safari */\n    color: rgba(100, 100, 100, 1) !important;\n}\n._1l8LWfd8101LQ6yQNcPB3P input::-moz-placeholder { /* Firefox 19+ */\n    color: rgba(100, 100, 100, 1) !important;\n}\n._1l8LWfd8101LQ6yQNcPB3P input:-ms-input-placeholder { /* IE 10+ */\n    color: rgba(100, 100, 100, 1) !important;\n}\n._1l8LWfd8101LQ6yQNcPB3P input:-moz-placeholder { /* Firefox 18- */\n    color: rgba(100, 100, 100, 1) !important;\n}\n\n\n._1l8LWfd8101LQ6yQNcPB3P input:focus::-webkit-input-placeholder { /* Chrome/Opera/Safari */\n    color: rgba(0, 0, 0, 1) !important;\n}\n._1l8LWfd8101LQ6yQNcPB3P input:focus::-moz-placeholder { /* Firefox 19+ */\n    color: rgba(0, 0, 0, 1) !important;\n}\n._1l8LWfd8101LQ6yQNcPB3P input:focus:-ms-input-placeholder { /* IE 10+ */\n    color: rgba(0, 0, 0, 1) !important;\n}\n._1l8LWfd8101LQ6yQNcPB3P input:focus:-moz-placeholder { /* Firefox 18- */\n    color: rgba(0, 0, 0, 1) !important;\n}\n\n._1l8LWfd8101LQ6yQNcPB3P div {\n  padding-bottom: 5px !important;\n}\n\n._1l8LWfd8101LQ6yQNcPB3P img {\n  position: absolute !important;\n  right: 0 !important;\n}\n", ""]);

// exports
exports.locals = {
	"chronoxInput": "_1l8LWfd8101LQ6yQNcPB3P"
};