import React from 'react';
import PropTypes from 'prop-types';
import OldView from 'Toolkit/OldView';

/**
 * Page: WorkReport editing (for employee)
 * 
 * Props:
 * - order: Id of WorkOrder the report is for
 * - day: Optional day if a "day report"
 */
export default function PageReportWorkReport(props) {
    
  const { order, day } = props.match.params;

  if(day) {
      sessionStorage.goToAfterPost = `report/workreports/order/${order}/day/${day}`;
      return <OldView mode="report" order={order} day={day} refresh={true} />;
  }
  else {
      sessionStorage.goToAfterPost = `report/workreports/order/${order}`;
      return <OldView mode="report" order={order} refresh={true} />;
  }
}

PageReportWorkReport.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      order: PropTypes.string.isRequired,
      day: PropTypes.string,
    }).isRequired,
  }).isRequired,
};
