import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';

import { Input, Button, Dimmer, Loader } from 'semantic-ui-react';

import { LOGIN_REQUEST, LOGIN_FAILURE } from '../Actions/constants';
import { connect } from 'react-redux';

import css from 'css/LoginForm.css';

function handleFocus() {
  app.ui.loginFocus();
}

/**
 * The LoginForm handles login. UI wise, it contains only two input fields and a button.
 * An application component should be created,
 * in order to wrap the form and make some specific styling.
 */
class LoginForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      company: props.company === null ? '' : props.company.id, // Initialize the company with the current company (NOTE that it might be null)
    };

    // In order for the login method to be accessible within the JSX code (render method)
    this.login = this.login.bind(this);
    // In order for the usernameChange method to be accessible within the JSX code (render method)
    this.usernameChange = this.usernameChange.bind(this);
    // In order for the passwordChange method to be accessible within the JSX code (render method)
    this.passwordChange = this.passwordChange.bind(this);
    // In order for the passwordChange method to be accessible within the JSX code (render method)
    this.organisationNumberChange = this.organisationNumberChange.bind(this);
    // In order for the handleKeyPress method to be accessible within the JSX code (render method)
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentDidMount() {
    const userPasswordDisable = this.props.shouldChooseCompany && this.props.company === null;

    if (!userPasswordDisable) {
      this.usernameInput.focus();
    }
  }

  /**
   * TODO
   * Create login function for administrators
   */
  login() {
    app.ui.login(this.state.username, this.state.company, this.state.password, 'companyAdmin');
  }

  /**
   * This method should be called whenever
   * the username field is changed (i.e. onChange={usernameChange}).
   *
   * @param event the event that triggered the call.
   *              This contains (for instance)
   *              information about what new value the username field obtained.
   */
  usernameChange(event) {
    this.setState({ username: event.target.value }); // Update the username variable
  }

  /**
   * This method should be called whenever the password field is changed
   * (i.e. onChange={passwordChange}).
   *
   * @param event the event that triggered the call. This contains (for instance)
   * information about what new value the password field obtained.
   */
  passwordChange(event) {
    // Update the password variable, with the change
    this.setState({ password: event.target.value });
  }

  /**
   * This method should be called whenever the organisation number field is changed
   * (i.e. onChange={organisationNumberChange}).
   *
   * Improvements
   * - Delay request for about 1-2 seconds
   * - If a request is ongoing, cancel it, and queue a new one (with delay)
   * - If a request is queued (not yet executed), update and delay it.
   *
   * x = new DelayedUpdater();
   * x.delay = 2000;
   * x.update(function() or Promise)
   * x.cancel()
   *
   * @param event the event that triggered the call. This contains (for instance)
   * information about what new value the organisationNumber field obtained.
   */
  organisationNumberChange(event) {
    const input = event.target.value;

    // Once the whole organisation number is entered
    if (this.props.shouldChooseCompany && input) {
      // Get the company
      app.api.getUserCompanies(null, input).then((response) => {
        let company = null;

        // @todo: Prevent us overwriting if the value has changed since we did our request
        // If a valid company was returned
        if (response.companies.length === 1) {
          [company] = response.companies;

          // Enable the username and login fields. Update the company state variable.
          this.setState({ company: company.id });
        } else {
          this.setState({ company: '' });
        }

        // If the is a callback
        if (this.props.onCompanyChange) {
          // Notify with the fetched company
          this.props.onCompanyChange(company);
        }
      });
    } else if (this.props.onCompanyChange) {
      this.props.onCompanyChange(null);
    }
  }

  handleKeyPress(event) {
    if (event.key === 'Enter' && this.state.username.length > 0 && this.state.password.length > 0) {
      this.login();
    }
  }

  render() {
    const loginDisabled = this.state.username.length < 1 || this.state.password.length < 1;
    const userPasswordDisable = this.props.shouldChooseCompany && this.props.company === null;
    const { t, loginError, isLoading } = this.props;

    const chooseCompanyElement = (
      <div>
        <Input className={css.chronoxInput} error={loginError} autoComplete="organization" name="organization" icon="industry" iconPosition="left" placeholder={t('organizationNumber')} fluid onChange={this.organisationNumberChange} onKeyPress={this.handleKeyPress} onFocus={handleFocus} defaultValue={this.props.company ? this.props.company.name : ''} />
        <Input className={css.chronoxInput} disabled={userPasswordDisable} error={loginError} name="username" icon="user" iconPosition="left" placeholder={t('username')} fluid onChange={this.usernameChange} onKeyPress={this.handleKeyPress} onFocus={handleFocus} ref={(input) => { this.usernameInput = input; }} />
        <Input className={css.chronoxInput} disabled={userPasswordDisable} error={loginError} name="password" icon="lock" iconPosition="left" placeholder={t('password')} fluid type="password" onChange={this.passwordChange} onKeyPress={this.handleKeyPress} onFocus={handleFocus} />
        <Button color="blue" fluid onClick={this.login} disabled={loginDisabled}>{t('logIn')}</Button>
      </div>
    );

    const noChooseCompanyElement = (
      <div>
        <Input className={css.chronoxInput} disabled={userPasswordDisable} error={loginError} icon="user" iconPosition="left" placeholder={t('username')} fluid onChange={this.usernameChange} onKeyPress={this.handleKeyPress} onFocus={handleFocus} ref={(input) => { this.usernameInput = input; }} />
        <Input className={css.chronoxInput} disabled={userPasswordDisable} error={loginError} icon="lock" iconPosition="left" placeholder={t('password')} fluid type="password" onChange={this.passwordChange} onKeyPress={this.handleKeyPress} onFocus={handleFocus} />
        <Button color="blue" fluid onClick={this.login} disabled={loginDisabled}>{t('logIn')}</Button>
      </div>
    );

    return (
      <Dimmer.Dimmable blurring>
        <Dimmer inverted active={isLoading}>
          <Loader />
        </Dimmer>
        {this.props.shouldChooseCompany ? chooseCompanyElement : noChooseCompanyElement}
      </Dimmer.Dimmable>
    );
  }
}

LoginForm.defaultProps = {
  isLoading: false,
  onCompanyChange: () => {},
};

LoginForm.propTypes = {
  t: PropTypes.func.isRequired,
  loginError: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  company: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
  shouldChooseCompany: PropTypes.bool.isRequired,
  onCompanyChange: PropTypes.func,
};

const mapStateToProps = state => Object.assign({}, {
  loginError: state.currentErrors.filter(error => error.type === LOGIN_FAILURE).length !== 0,
  isLoading: state.currentRequests.filter(request =>
    request.type === LOGIN_REQUEST).length > 0 || state.auth.isSignedIn,
  company: state.company,
  shouldChooseCompany: state.shouldChooseCompany,
});

const LoginFormContainer = connect(mapStateToProps)(LoginForm);

export default translate()(LoginFormContainer);
