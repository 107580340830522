import React from 'react';
import OldView from 'Toolkit/OldView.jsx';

class PageSettings extends React.Component
{
    render()
    {
        return(
            <OldView mode="settings" refresh={false} />
        );
    }

}

export default PageSettings;
