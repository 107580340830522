import React from 'react';

import {Button} from 'semantic-ui-react';

/**
 * A positive button is one that signifies an action that is considered "safe".
 * The user should feel comfortable with pressing it.
 */
class NewObjectButton extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
        	hover: false, // The initial hover state is "not hovered"
        };
        
        this.hover = this.hover.bind(this);       // In order for the hover() function to be accessible in the jsx in the render function
        this.notHover = this.notHover.bind(this); // In order for the notHover() function to be accessible in the jsx in the render function
    }

    /** This function handles the hover event, it should be called by the object that is being hovered (i.e. the button). */
	hover() {
		this.setState({hover: true});
	}

    /** This function handles the "unhover" event (when the mouse moves aways from the button), it should be called by the object that is being "unhovered" (i.e. the button). */
	notHover() {
		this.setState({hover: false});
	}

	/** The render method is called by React, and returns the JSX element to be rendered. */
    render() {

        /* This style is always applied */
  		const buttonStyle = {
			lineHeight: '1em',
			boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.4)',
  		};

  		/* Create the hover based css. */
		const hoverStyle =
		this.state.hover ?
		{
			backgroundColor: 'rgba(65, 131, 196, 0.6)', // If the button is hovered, use a darker (clearer) blue
		}
		:
		{
			backgroundColor: 'rgba(65, 131, 196, 0.4)',  // If it is not hovered, use a lighter blue
		};

		const finalStyle = Object.assign({}, buttonStyle, hoverStyle); // Concatenate the buttonStyle with the conditioned hoverStyle

        /* The element that is rendered. It uses the passed down onclick event, wraps the passed down children and handles hovering */
        const element = (<Button onClick={this.props.onClick} onMouseEnter={this.hover} onMouseLeave={this.notHover} style={finalStyle}>{this.props.children}</Button>);

        return element;
    }
}

export default NewObjectButton;
