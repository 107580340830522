import React from 'react';

import { Table } from 'semantic-ui-react';

class List extends React.Component {
  
	render() {
	    const headings = this.props.headings.map((heading, index) => // Create an array of headings (table heads)
	      <Table.HeaderCell key={heading + index}>{heading}</Table.HeaderCell>
	    );

	    const items = this.props.items.map((item, index) => // Create an array of items (table rows)
	    {
	      var cells = [<td key={item + index}>{item}</td>];  // Defualt value (if the item is not an array)

	      if(Array.isArray(item))                    // If the item is an array
	        cells = item.map((cell, cellIndex) =>               // Create an array of cells
	          <Table.Cell key={item + index + cellIndex}>{cell}</Table.Cell>             // Each cell is a td element
	        );

	      return <Table.Row key={item}>{cells}</Table.Row>;        // Return the final table row
	    });
	    
	    const element = (
	      <Table size='small' collapsing unstackable selectable className={this.props.className} >
	        <Table.Header>
	          <Table.Row>
	            {headings}
	          </Table.Row>
	        </Table.Header>
	        <Table.Body>
	          {items}
	        </Table.Body>
	      </Table>
		);
	    
	    return element;
	}
	
	oldRender() {
    const headings = this.props.headings.map((heading, index) => // Create an array of headings (table heads)
      <th key={heading + index}>{heading}</th>
    );

    const items = this.props.items.map((item, index) => // Create an array of items (table rows)
    {
      var cells = [<td key={item + index}>{item}</td>];  // Defualt value (if the item is not an array)

      if(Array.isArray(item))                    // If the item is an array
        cells = item.map((cell, cellIndex) =>               // Create an array of cells
          <td key={item + index + cellIndex}>{cell}</td>             // Each cell is a td element
        );

      return <tr key={item}>{cells}</tr>;        // Return the final table row
    }
    );

    const element = (
      <table className= {"dataTable " + this.props.className} cellSpacing="0" cellPadding="0">{/* Add possible class names here. How about cell-spacing and cell-padding? */}
        <thead>
          <tr>
            {headings}{/* Add the headings here */}
          </tr>
        </thead>
        <tbody>
          {items}{/* Add the rows here */}
        </tbody>
      </table>
    );

    return element;
  }
}

List.defaultProps = {
  items: [["The list is empty",  "", "", "", "", ""]],
  headings: [""]
};

export default List;
