import React from 'react';
import OldView from 'Toolkit/OldView.jsx';

class PageWorkReportConfig extends React.Component {
    render() {
        const id = this.props.match.params.configId;

        return <OldView mode="settingsShowConfig" type="WorkReport" id={id} refresh={false} />
    }
}

export default PageWorkReportConfig;
