import React from 'react';
import OldView from 'Toolkit/OldView.jsx';
import QueryRoute from 'Toolkit/QueryRoute.jsx';
import { Switch } from 'react-router-dom';

class PageLedger extends React.Component
{
    render() {
        const ledger = this.props.params.ledger || 1;
        const grouped = this.props.query.grouped || 0;
        const url = this.props.url;

		console.log("PageLedger: params.ledger = ", this.props.params);
		if(this.props.params.ledger == "https:") {
			alert("wierd");
		}

        return (
            <div>
                <Switch>
                    <QueryRoute path={`${url}/settings`} render={props => <OldView mode="ledgerSettings" ledger={ledger} grouped={grouped || 0} tab={props.query.tab || "employees"} refresh={false} />}  />
                    <QueryRoute render={props => <OldView mode="ledger" ledger={ledger} grouped={grouped} refresh={true} />} />
                </Switch>
            </div>
        );
    }
}

export default PageLedger;
