import React from 'react';
import OldView from 'Toolkit/OldView.jsx';

class PageEmployees extends React.Component
{
    render()
    {
        return(
            <OldView mode="employees" refresh={false} />
        );
    }

}

export default PageEmployees;
