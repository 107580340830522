import React from 'react';
import OldView from 'Toolkit/OldView.jsx';

class PageProject extends React.Component {
    render() {
        const project = this.props.match.params.project;

        return <OldView mode="projectEdit" project={project} refresh={false} />;
    }
}

export default PageProject;
//