import React from 'react';

import { translate } from 'react-i18next';
import { NewObjectButton } from 'Toolkit/Elements';

/**
 * A component that presents a NewObjectButton. When the user presses the component (button),
 * a window for creating a new project appears.
 */
class AddProjectButton extends React.Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    /**
     * Gets called when the button is pressed.
     * Put the code that should execute in the event of a button click, inside this function.
     * */
    handleClick() {
        location.href='?mode=projectAdd';
    }

    /**
     * Used by react in order to render the UI element.
     * It should return either a JSX (React JSX) element, or an object generated by React.createElement().
     * */
    render() {
        const { t } = this.props;
        const element = (<NewObjectButton onClick={this.handleClick}>{t('AddProjectButton.addProject')}</NewObjectButton>);

        return element;
    }
}

/* The translate call is responsible */
export default translate('Toolkit')(AddProjectButton);
