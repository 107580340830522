/* eslint max-len: warn */
import i18n from 'i18n';

import {
  PAGE_LOAD,
  LOGIN_FOCUS,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  SET_LOGIN_BOX_TAB,
  SET_COMPANY,
  SET_MENU,
  SET_BODY_CLASSES,
  SET_LANGUAGE_DROPDOWN_STATUS,
  SIDEBAR_CLICK,
  PAGE_CHANGE,
  CLICK_ON_HEADER,
  CLICK_ON_CONTENT,
  NEW_VERSION_ALERT,
  LANGUAGE_CHANGE,
  CENTRAL_DIALOG_OPEN,
  CENTRAL_DIALOG_CLOSE,
} from '../Actions';

const centralDialogTypes = {
  info: 'info',
  warn: 'warn',
  error: 'error',
};

const initialState = {
  /**
   * Authentication / Authorization related states
   */
  auth: {
    /**
      * The last received accessToken; is used with all API-requests and reused when doing new logins
      */
    accessToken: null,
    /**
     * Current role of the user. Either 'employee', 'companyAdmin' or NULL (if unknown). This affects
     * the root view of the application. This may be tied to which role was indicated/authorized when
     * last updating the accessToken.
     */
    currentRole: null,
    /**
     * Main state of the application, if the user is signed in or not. That is, whether or not to go
     * to the LoginScreen or AppScreen. Note that this does not necessarily mean that the user has a
     * valid session *NOW*, but rather that the application should assume that. For example, if this
     * is true and verification of the accessToken fails, the application should automatically try to
     * renew the accessToken, because the user *SHOULD* be signed in (not necessarily that he is).
     * However, if it is false, it means that we should not attempt to login automatically, because
     * we have explicitly logged out.
     */
    isSignedIn: false,
    /**
     * The URL or other indicator returned by the server/API when last renewing the accessToken.
     * This could be regarded as the HOME path for the current session. For the web app this is the
     * actual URL, for a full react native app, this could have a different syntax.
     */
    startLocation: null,
  },

  /**
   * Current Company (Account) that the application is configured to
   *
   * This does NOT imply any authentication; the user does not have to log in for this to be set. It
   * could be remembered from the last session or set from environmental information. Normally it is
   * set/updated by auth/login or auth/info, or when user selects a company
   *
   * It could be set by the user entering an account-specific URL (/konto/company/) or if we released
   * a preconfigured app for a customer, this is the company information to use as context. In the
   * future we may support users to be logged in without belonging to a company.
   *
   * We may need to keep track of if a company was originally specified or if it is just "temporarily"
   * set. This will probably need to be handled by another variable. Note that only public/safe
   * information about the company is stored here.
   *
   * @type CompanyBrief { id, id2, name, identification, logoUrl }
   */
  company: null,

  /**
   * The current authenticated user and its personal information
   *
   * This information may be retained after a logout (explicit or background) so should not be a test
   * for if the application is in authenticated state. For that auth/isSignedIn should be used.
   *
   * @type UserInfo { id, name, username, email, roles[] }
   */
  user: null,

  shouldChooseCompany: true,

  // FIXME: These could probably be moved into a config sub-object, if they are necessary at all in the store (not meant to be changed during application life-cycle)
  urlPrefix: null,
  buildFilePath: null,
  apiLocation: '',
  contentViewApiLocation: '',
  contentComponentApiLocation: '',

  path: [],
  menu: [],
  chosenLanguage: i18n.language,
  loginBox: {
    selectedTab: 0,
  },
  // NOTE: All reducers that adds requests could be grouped into one separate reducer
  currentRequests: [],
  // The same thing applies to currentErrors
  currentErrors: [],
  bodyClasses: [],
  preloaded: {
    scriptsSrc: [],
    scriptsTag: [],
  },
  languageMenuOpen: false,
  sidebarVisible: false,
  lastEvent: {
    action: {
      type: 'INITIAL_STATE',
      time: new Date(),
    },
  },
  centralDialog: {
    open: false,
    type: centralDialogTypes.info,
    locked: false,
    callback: null,
    header: '',
    message: '',
  },
};

/**
 * The master reducer of the application.
 */
export default (oldState = initialState, action) => {
  // For each event/action, update the lastEvent object in the state.
  // This object contains the action that was last made, and the time it was called.
  const state = Object.assign({}, oldState, {
    lastEvent: {
      action,
      time: new Date(),
    },
  });

  switch (action.type) {
    case PAGE_LOAD:
      return Object.assign({}, state, {
        auth: Object.assign({}, state.auth, {
          accessToken: action.info.accessToken,
          isSignedIn: action.info.auth.accessToken && action.info.user,
        }),
        urlPrefix: action.info.urlPrefix,
        buildFilePath: action.info.buildFilePath,
        company: action.info.company || null,
        shouldChooseCompany: !action.info.company,
        menu: action.info.menu,
        user: action.info.user || { name: '' },
        apiLocation: action.info.apiLocation,
        contentViewApiLocation: action.info.contentViewApiLocation,
        contentComponentApiLocation: action.info.contentComponentApiLocation,
        preloaded: action.info.preloaded,
      });
    case LOGIN_FOCUS:
      return Object.assign({}, state, {
        // Remove the LOGIN_ERRORS from the list of current errors
        currentErrors: state.currentErrors.filter(error => error.type !== LOGIN_FAILURE),
      });
    case LOGIN_REQUEST:
      return Object.assign({}, state, {
        currentRequests: [
          ...state.currentRequests,
          {
            type: LOGIN_REQUEST,
            text: 'Trying to login...',
          },
        ],
      });
    case LOGIN_SUCCESS: // NOTE: This should also add the "startKit" to the state
      return Object.assign({}, state, {
        // Change is signedIn to true
        auth: Object.assign({}, state.auth, { isSignedIn: true }),
        // Remove the LOGIN_REQUEST from the list of current requests
        currentRequests: [],
        // Remove the LOGIN_ERRORS from the list of current errors
        currentErrors: [],
      });
    case LOGIN_FAILURE:
      return Object.assign({}, state, {
        currentErrors: [
          ...state.currentErrors,
          {
            type: LOGIN_FAILURE,
            // NOTE: This should be derived from the errorObject
            text: 'Login failed',
          },
        ],
        currentRequests: state.currentRequests.filter(request => request.type !== LOGIN_REQUEST),
      });
    case LOGOUT:
      return Object.assign({}, state, {
        auth: Object.assign({}, state.auth, { accessToken: null, isSignedIn: false }),
      });
    case SET_LOGIN_BOX_TAB:
      return Object.assign({}, state, {
        loginBox: Object.assign({}, state.loginBox, { selectedTab: action.tabIndex }),
      });
    case SET_COMPANY:
      return Object.assign({}, state, { company: action.company });
    case SET_MENU:
      return Object.assign({}, state, { menu: action.menu });
    case SET_BODY_CLASSES:
      return Object.assign({}, state, { bodyClasses: action.classes });
    case SET_LANGUAGE_DROPDOWN_STATUS:
      return Object.assign({}, state, {
        languageMenuOpen: action.status,
      });
    case SIDEBAR_CLICK:
      return Object.assign({}, state, { sidebarVisible: !state.sidebarVisible });
    case CLICK_ON_HEADER:
      if (state.sidebarVisible) {
        // Set to true if the last event was SIDEBAR_CLICK
        const sidebarWasClicked = oldState.lastEvent.action.type === SIDEBAR_CLICK;
        // Set to true if the last event occured in the last 500 milliseconds
        const eventWasRecent =
          oldState.lastEvent.time.getTime() - state.lastEvent.time.getTime()
          < 1000;

        // If the last event was SIDEBAR_CLICK and it was recent,
        // the state should not change.
        if (sidebarWasClicked && eventWasRecent) {
          return state;
        }
      }

      // If there was no problem, make sure that the sidebar is not visible
      return Object.assign({}, state, { sidebarVisible: false });
    case PAGE_CHANGE:
    case CLICK_ON_CONTENT:
      // Make sure that the sidebar is not visible
      return Object.assign({}, state, { sidebarVisible: false });

    case NEW_VERSION_ALERT:
      return Object.assign({}, state, {
        centralDialog: {
          open: true,
          type: centralDialogTypes.info,
          locked: action.forceRefresh,
          callback: () => window.location.reload(),
          header: i18n.t('newVersionHeader'),
          message: i18n.t('newVersionMessage'),
        },
      });

    case CENTRAL_DIALOG_OPEN:
      return Object.assign({}, state, {
        centralDialog: {
          open: true,
          type: action.dialog.type || centralDialogTypes.info,
          locked: true,
          callback: action.dialog.callback || null,
          header: action.dialog.header || i18n.t('dialogHeaderInfo'),
          message: action.dialog.message,
        },
      });
      
    case CENTRAL_DIALOG_CLOSE:
      return Object.assign({}, state, {
        centralDialog: {
          open: false,
          type: null,
          locked: false,
          callback: null,
          header: null,
          message: null,
        },
      });
      
    case LANGUAGE_CHANGE:
      return Object.assign({}, state, {
        language: action.language,
      });
    default:
      return state;
  }
};

export { centralDialogTypes };
