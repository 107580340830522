import React from 'react';
import { translate } from 'react-i18next';

import LedgerIndexTable from './LedgerIndexTable.jsx';

class LedgerOverviewBlock extends React.Component {
	constructor(props)
	{
		super(props);
		this.state = {
			rows: [/*{
				locationName: "-",
				locationAccessCode: "-",
				mode: "-",
				firstLogTime: "-",
				lastLogTime: null, 
				workersCheckedInCount: "-",
				participantsCount: "-",
				url: "-"
			}*/],
			dataError: false
		}
	}

	componentDidMount() 
	{
		app.api.call("ledgerlist", "GET", {status: "active"})
		.then((response) => this.setState({ rows: response.rows }))
		.catch((response) => this.setState({ dataError: true }));
	}

	render() 
	{
		const { t } = this.props;

		if(this.state.rows) {
			return (
				<section>
					<h2 className="forBox">{t('LedgerOverviewBlock.title')}</h2>
					<LedgerIndexTable rows={this.state.rows} error={this.state.dataError} />
				</section>
			);
		}
		else {
			return (null);
		}
	}
}

export default translate('Toolkit')(LedgerOverviewBlock);
