
/**
 * The config object contains everything that is platform specific. Things that are based on the environment where the application is installed
 * When installing the application on a server, make sure that this config object is modified to include everything.
 */

const defaultConfig = {
  urlPrefix: null,
  company: null,
  menu: null,
  user: null,
  auth: {
    accessToken: null,
  },
  buildFilePath: 'bundle.js',
  productionBuildFilePath: 'bundle.js',
  localesPrefix: '',
  installPath: '',
  useSuggestedLocation: true,
  apiLocation: "https://www.chronox.se/konto2/api/v1/",
  contentViewApiLocation: "https://www.chronox.se/konto/app/v2/:company/content/view/",
  contentComponentApiLocation: "https://www.chronox.se/konto/app/v2/:company/content/component/",
  preloaded: {
    scriptsSrc: [],
    scriptsTag: []
  },
  languageDisabled: false,

  /**
   * Allowed / available languages in the application. The first is considered default/fallback language
   */
  languageWhitelist: [ 'sv', 'en'],

  /**
   * The name of the cookie in which to store session id/accessToken, should match the servers
   */
  sessionCookieName: 'site_sid2',

  /**
   * The explicit domain to set on session cookie, should match the servers
   */
  sessionCookieDomain: null,

  /**
   * Lifetime of the session cookie in days. Set to NULL for session cookie without expire time
   */
  sessionCookieExpiresAfterDays: null, // 365,
};

const appState = window.appState || {};

const jsonConfig = window.config || {}; 


const config = Object.assign({}, defaultConfig, jsonConfig, appState);

__webpack_public_path__ = config.installPath; // Set the path of the assets

export default config;
