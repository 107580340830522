import React from 'react';
import ReactDOM from 'react-dom';

class ContentSubMenu extends React.Component {
  render() {
	// If no items
	return null;
    // If items
    return (
   		<div id="submenu">
			<ul>
			</ul>
		</div>
    );
  }
}

export default ContentSubMenu;