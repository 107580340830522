import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button } from 'semantic-ui-react';

import { Chronox, Company } from 'Toolkit/Logo';
import { sidebarClick, clickOnHeader } from './Actions';

class ContentHeader extends React.Component {
  constructor(props) {
    super(props);
    this.headerElement = React.createRef();
  }

  componentDidMount() {
    window.requestAnimationFrame(() => {
      const { offsetHeight } = this.headerElement.current;
      this.props.onMount(offsetHeight);
    });
  }

  render() {
    const {
      mobile,
      onHeaderClick,
      onButtonClick,
    } = this.props;

    const circularButtonStyle = mobile ? { float: 'left' } : { display: 'none' };

    return (
      <div id="header" ref={this.headerElement} className="clearfix" onClick={onHeaderClick} role="none">
        <Button basic icon="sidebar" style={circularButtonStyle} onClick={onButtonClick} />
        <Company style={{
              display: 'block',
              float: 'left',
              width: 'auto',
              maxHeight: '50px',
              maxWidth: '33%',
          }}
        />
        <Chronox style={{
            display: 'block',
            float: 'right',
            width: 'auto',
            maxHeight: '50px',
            maxWidth: '33%',
          }}
        />
      </div>
    );
  }
}
ContentHeader.defaultProps = {
  onMount: () => console.log('No onMount handler for ContentHeader.'),
};


ContentHeader.propTypes = {
  mobile: PropTypes.bool.isRequired,
  onHeaderClick: PropTypes.func.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  onMount: PropTypes.func,
};

const mapDispatchToProps = dispatch => Object.assign({}, {
  onButtonClick: () => dispatch(sidebarClick()),
  onHeaderClick: () => dispatch(clickOnHeader()),
});

export default connect(null, mapDispatchToProps)(ContentHeader);
