import React from 'react';
import { Route } from 'react-router-dom';

/**
 * This component extends react-router-dom's Route component, by passing down a query prop.
 * Given the query ?key=vaulue&key2=value2, the passed down query prop will be {key: value, key2: value}.
 * The queries will be accessible through this.props.query.key and this.props.query.key2.
 * It accepts a component (reference) or render (function) as a prop, and renders a route.
 * */
function QueryRoute(props) {
    var queryParam = getQueryVariables(window.location.search.substring(1));
    var RenderComponent = props.component;
    var routeParams = Object.assign({}, props);
    delete routeParams['component'];

    var thisProps = props;
	var renderFunction = props => {
		if(thisProps.render)
			return thisProps.render(Object.assign({}, props, {params: props.match.params, query: queryParam}));
		return <RenderComponent url={props.match.url} params={props.match.params} query={queryParam} />;
	};
	
	console.log("QueryRoute: routeParams = ", routeParams, renderFunction);

	return <Route {...routeParams} render={renderFunction} />;
}

function getQueryVariables(query) {
    var result = {};

    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        let pair = vars[i].split('=');
        let key = decodeURIComponent(pair[0]);
        let value = decodeURIComponent(pair[1]);
        result[key] = value;
    }

    return result;
}

export default QueryRoute;

