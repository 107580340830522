import React from 'react';
import OldView from 'Toolkit/OldView.jsx';

class PageEmployee extends React.Component {
    render() {
        const employee = this.props.match.params.employee;
        if(employee == "new") {
            return <OldView mode="employeeAdd" refresh={false} />;
        }
        else {
            return <OldView mode="employeeEdit" employee={employee} refresh={false} />;
        }
    }
}

export default PageEmployee;