import React from 'react';
//import QueryParser from 'Toolkit/QueryParser.jsx';
import { Redirect, withRouter } from 'react-router-dom';
import OldLinksWrapper from 'Toolkit/OldLinksWrapper.jsx';
import $ from 'jquery';
import queryString from 'query-string';
import { translate } from 'react-i18next';

/**
 * 
 */
class OldView extends React.Component 
{
	constructor(props)
	{
		super(props);
		this.state = {
			loaded: false,
			content: null,
			linkStylesheets: [],
			headStyleRules: [],
			headScripts: [],
			headJS: [],
			headJSReady: [],
			classes: [],
			path: null,
			title: null,
			fromPostContent: false,
		};
		this._added = false;
		this._isRefreshing = (props.refresh && app.pageSwitchesCount > 0 ) || app.postContent;  // Refresh the page completely if the refresh prop is passed down, or if postContent exists (i.e. if a post occurred) 
		this._isRefreshing = (props.refresh && app.pageSwitchesCount > 0 );  // Refresh the page completely if the refresh prop is passed down, or if postContent exists (i.e. if a post occurred) 

		// Set the view's own params by copying ours and removing our params
		let pageProps = queryString.parse(location.search);
		this._viewParams = Object.assign({}, pageProps, this.props);
		delete this._viewParams.refresh;  
		delete this._viewParams.t;
		delete this._viewParams.i18n;
		
		if(this._isRefreshing) {
            console.log("OldView reloading (" + app.pageSwitchesCount);
            location.reload();
        }

		if(app.postContent && app.pageSwitchesCount == 0) {
			console.log("OldView got postContent ----");
			this.state = {
				loaded: true,
				content: app.postContent.html(),
				fromPostContent: true,
			}
			app.postContent = null;
		}
		else {
			console.log("OldView no postContent");
		}

		this._getOldView = this._getOldView.bind(this);

	}
	
	componentWillReceiveProps(nextProps)
	{
		console.log("OldView::componentWillReceiveProps(): ", this.props, nextProps);
		return;
		// Hopefully this happens only when we are truely changed
		this.setState({
			loaded: false,
			content: null,
		});		
		this._added = false;

		this._viewParams = Object.assign({}, nextProps);
		delete this._viewParams.refresh;  
		delete this._viewParams.t;
		delete this._viewParams.i18n;
		  
		this._getOldView();
	}

	componentDidUpdate()
    {
		if(this.state.loaded && !this._added) {
			// NOTE: This should probably be divided; everything, except JSReady, could probably be done before rendering (eg DidMount)
			app.document.setPage(this);
			app.document.addComponent(this);
	    	if(this.state.headJSReady) { 
	    		//eval(this.state.headJSReady['']);
	    	}
	    	this._added = true;
		}

		// FIXME: remove jquery dependency
        $('.forBox').each(function() {
            let next = $(this).next();
            let wrapper = $('<div class="HTMLDocumentComponent-wrapper"></div>').css("overflow-x", 'auto');
            next.wrap(wrapper);
        });

		/*const title = this.state.title;
		$('table.dataTable').each(function(i, e) {
			$(this).attr('id', title + '-dataTable-' + i);
		});*/
    }


	componentDidMount()
	{
		// Don't do ANYTHING if we are forcerefreshing the view
		if(this._isRefreshing) {
			return;
		}

		if(!this.state.loaded) {
			this._getOldView();
		}

		app.incrementPageSwitches();
		
		// Only if the page was fetched from post content and the readyScripts variable exists
		if (this.state.fromPostContent && window.readyScripts) {
			// The jquery ready handlers are hijacked in SiteDocument.php.
			// They are stored in the global variable called readyScripts.
			window.readyScripts.forEach(handler => {
				// Call each handler
				handler.apply(window.$);
			});

			// Delete the readyScripts variable, just to be safe.
			delete window.readyScripts;
		}
	}

	_getOldView()
	{
//	    if(app.postContent) {
//	        this.setState({
//	           loaded: true,
//	           content: app.postContent.html(),
//	           linkStylesheets: [],
//               headStyleRules: [],
//               headScripts: [],
//               headJS: [],
//               headJSReady: [],
//	        });
//	        app.postContent = null;
//	        return;
//	    }
	    
		app.api.getOldView(this.props.mode, this._viewParams).then((response) => {
			this.setState({
				loaded: true,
				content: response.component.content,
				linkStylesheets: response.component.linkStylesheets,
				headStyleRules: response.component.headStyleRules,
				headScripts: response.component.headScripts,
				headJS: response.component.headJS,
				headJSReady: response.component.headJSReady,
				classes: response.component.class,
				path: response.component.path,
				title: response.component.title,
			});
		}).catch((response) => {
			console.error("OldView: failed request " + response);
			this.setState({
				loaded: false,
				content: `<span className="error">${this.props.t('renderingError')}</span>`,
			});
		});
	}
	
	render()
	{
		const content = <div dangerouslySetInnerHTML={{__html: this.state.content}}/>;
		return (
			<div className={"OldView-wrapper " + (this.state.loaded ? 'is-loaded' : '')} style={{maxWidth: 'auto', margin: 'auto'}}>
				<OldLinksWrapper>{content}</OldLinksWrapper>
			</div>
		);
	}
}

OldView.defaultProps = {
	refresh: true
};

export default translate()(OldView);
