import React from 'react';
import PropTypes from 'prop-types';
import { Button, Flag } from 'semantic-ui-react';

/**
 * The LanguageChooser component generates buttons for the supported languages,
 * and handles the event of a change in language.
 * It expects a callback function with one parameter.
 *
 * Example: <LanguageChooser
 *            options={[
 *              {countryCode: 'se', languageCode: 'sv'},
 *              {countryCode: 'gb', languageCode: 'en'}
 *            ]}
 *            value={'sv'}
 *            onChange={function() { ... some handler code ... }}
 *           />
 * */
class LanguageChooser extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event, option) {
    if (option !== this.props.value) {
      this.props.onChange(event, option);
    }
  }

  render() {
    const { options, value } = this.props;

    const buttons = options.map(option => (
      <Button
        key={option.countryCode}
        active={option.languageCode === value}
        onClick={() => this.handleClick(option.languageCode)}
      >
        <Flag name={option.countryCode} />
      </Button>
    ));

    const element = (
      <Button.Group basic size="tiny">
        {buttons}
      </Button.Group>
    );

    return element;
  }
}

LanguageChooser.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};


LanguageChooser.defaultProps = {
  value: 'sv',
};

export default LanguageChooser;
