import React from 'react';

import { Header } from 'Toolkit/Elements';

/**
 * A section is a sort of wrapper for content within a view. The philosophy is that everything inside of a view, is wrapped in a section.
 * In other words, a view is composed of several sections. A section can be seen as anything that should have an H1 header.
 */
class Section extends React.Component {
    render() {
        /* Use 100% width and an unlimited height (this can be changed in the future, but should still be the default). */
        const sectionStyle = {
            width: '100%',
            height: 'auto',
            padding: '0',
            margin: '0',
        };

        /* The element that will be rendered. It wraps the content in a simple div and adds a header */
        const element = (
            <div style={sectionStyle}>
            	<Header size='medium'>{this.props.title}</Header>
                {this.props.children}
            </div>
        );

        return (element);
    }
}

export default Section;
