import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Dimmer, Loader, Input, Button } from 'semantic-ui-react';

import ChronoxDropdown from './Dropdown/ChronoxDropdown';
import { LOGIN_REQUEST, LOGIN_FAILURE } from '../Actions/constants';
import css from 'css/LoginForm.css';

function handleFocus() {
  app.ui.loginFocus();
}

/**
 * The LoginForm handles login. UI wise, it contains only two input fields and a button.
 * An application component should be created,
 * in order to wrap the form and make some specific styling.
 */
class LoginForm extends React.Component {
  constructor(props) {
    super(props);

    /* The initial state of the component */
    this.state = {
      // Initialize the username as blank
      username: '',
      // Initialize the password as blank
      password: '',
      // The companies of the entered user.
      // This is updated when a complete and valid personal number is entered.
      companies: [],
      // The currently selected company
      company: props.company === null ? '' : props.company.name,
      companyId: props.company === null ? '' : props.company.id,
      isLoadingOverride: false,
    };

    // Bind the methods,
    // in order for the login method to be accessible within the JSX code (render method)
    this.login = this.login.bind(this);
    this.usernameChange = this.usernameChange.bind(this);
    this.passwordChange = this.passwordChange.bind(this);
    this.companyChange = this.companyChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);

    // This is fixes the problem of the spinner being stuck if a user
    // uses the browser history back function, to go back to the login.
    // The isLoadingOverride flag in the state acts as an override.
    // If set to true, it overrides the isLoading prop.
    // In most browsers, the unload event is where the page cache occurs.
    // This means that this fix overrides the caching of the page,
    // which ultimately solves the issue. We can still keep,
    // the isLoadingOverride, to be safe.
    window.addEventListener('unload', (event) => {
      event.preventDefault();
      this.setState({ isLoadingOverride: true });
    });
  }

  /* FIXME: update the ref handling to use React.createRef(), which was introduced in react 16.3 */
  componentDidMount() {
    this.usernameInput.focus();
  }

  login() {
    // Reset the unload override
    this.setState({ isLoadingOverride: false });

    const company = this.state.companies.length > 1 ? this.state.companyId : null;
    app.ui.login(this.state.username, company, this.state.password);
  }

  /**
    * This method should be called whenever the username field is change
    * (i.e. onChange={usernameChange}).
    *
    * @param event the event that triggered the call.
    * This contains (for instance) information about what new value the username field obtained.
    */
  usernameChange(event) {
    const newValue = event.target.value;

    this.setState({ username: newValue });

    // Only if the companies have not already been fetched
    if (this.state.companies.length < 1) {
      app.api.getUserCompanies(newValue)
        .then((response) => {
          this.setState({
            companies: response.companies.map(company => Object.assign({}, {
              text: company.name,
              value: company.id2,
              image: {
                rounded: true,
                src: company.logoUrl,
                floated: 'right',
              },
            })),
          });

          // Only if it is a non-company login (i.e. /konto/ and not /konto/<company>/)
          if (this.props.shouldChooseCompany && !this.state.company && !this.state.companyId) {
            if (response.companies.length === 1) {
              this.setState({
                company: response.companies[0].name,
                companyId: response.companies[0].id2,
              });
              this.props.onCompanyChange(response.companies[0]);
            }
          }
        })
        // The catch is needed, even if no handler exists
        .catch(() => {});
    }
    console.log(newValue.length);
    if (newValue.length <= 0) {
      this.setState({ password: '' });
    }
  }

  /**
     * This method should be called whenever the
     * password field is change (i.e. onChange={passwordChange}).
     *
     * @param event the event that triggered the call.
     * This contains (for instance) information about what new value the password field obtained.
     */
  passwordChange(event) {
    // Update the password variable, with the change
    this.setState({ password: event.target.value });
  }

  companyChange(event, { value }) {
    // If the chosen element text does not match any of the companies in the state,
    // then it is not a valid choice and we should ignore it.
    const choiceIsAcceptable = this.state.companies.find(company =>
      company.text === event.target.textContent) !== undefined;
    // This if statement prevents a bug where the dropdown placholder receives odd values.
    // It checks if the value of the changed item exists (i.e. something was chosen),
    // and if that value is different from the previous one.
    // If it returns false, the textContent is equal to a falsy string.
    if (choiceIsAcceptable && value !== this.state.companyId) {
      this.setState({ company: event.target.textContent, companyId: value });
    }
  }

  handleKeyPress(event) {
    if (event.key === 'Enter' && this.state.username.length > 0 && this.state.password.length > 0) {
      this.login();
    }
  }

  render() {
    const { companies, isLoadingOverride } = this.state;
    const {
      t,
      loginError,
      isLoading,
      shouldChooseCompany,
    } = this.props;
    const loginDisabled = this.state.username.length < 1 || this.state.password.length < 1;
    const dropdownHidden = companies.length < 2 || !shouldChooseCompany;

    const element = (
      <Dimmer.Dimmable blurring>
        <Dimmer inverted active={!isLoadingOverride && isLoading}>
          <Loader />
        </Dimmer>
        <Input className={css.chronoxInput} error={loginError} icon="user" iconPosition="left" placeholder={t('usernameReporter')} fluid onChange={this.usernameChange} onKeyPress={this.handleKeyPress} onFocus={handleFocus} ref={(input) => { this.usernameInput = input; }} />
        <ChronoxDropdown placeholder={this.state.company || t('Toolkit:LoginForm.chooseCompany')} icon="industry" options={companies} hidden={dropdownHidden} onChange={this.companyChange} />
        <Input className={css.chronoxInput} error={loginError} icon="lock" iconPosition="left" placeholder={t('password')} fluid type="password" onChange={this.passwordChange} onKeyPress={this.handleKeyPress} onFocus={handleFocus} value={this.state.password} />
        <Button color="blue" fluid onClick={this.login} disabled={loginDisabled}>{t('logIn')}</Button>
      </Dimmer.Dimmable>
    );

    return element;
  }
}

LoginForm.propTypes = {
  company: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
  }),
  onCompanyChange: PropTypes.func,
  isLoading: PropTypes.bool,
  t: PropTypes.func.isRequired,
  loginError: PropTypes.bool.isRequired,
  shouldChooseCompany: PropTypes.bool.isRequired,
};

LoginForm.defaultProps = {
  onCompanyChange: () => {},
  isLoading: false,
  company: null,
};

const mapStateToProps = state => Object.assign({}, {
  // If there is a login error, set the loginError prop to true
  loginError: state.currentErrors.filter(error => error.type === LOGIN_FAILURE).length > 0,
  // If there is a login request (or success), set the isLoading prop to true
  isLoading: state.currentRequests.filter(request =>
    request.type === LOGIN_REQUEST).length > 0 || state.auth.isSignedIn,
  company: state.company,
  shouldChooseCompany: state.shouldChooseCompany,
});

const LoginFormContainer = connect(mapStateToProps)(LoginForm);

export default translate(['Common', 'Toolkit'])(LoginFormContainer);
