import React from 'react';

import LoginBox from 'App/LoginBox.jsx';
import ChronoxColorBox from 'Toolkit/ChronoxBox/ChronoxColorBox.jsx';

/**
 * A LoginWindow adds a white box around an instance of LoginBox.
 * This component can be used directly by a view.
 */
class LoginWindow extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        /* The element to be rendered. */
        const element = (
            <ChronoxColorBox style={{display: "block", width: "95.3%", maxWidth: "500px"}}>
                <LoginBox />
            </ChronoxColorBox>
        );

        return (element);
    }
}

export default LoginWindow;
