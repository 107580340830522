import React from 'react';
import OldView from 'Toolkit/OldView.jsx';

class PageUsers extends React.Component
{
    render()
    {
      //throw {name : "NotImplementedError", message : "too lazy to implement"};
        return(
            <OldView mode="users" refresh={false} />
        );
    }

}

export default PageUsers;
