import React from 'react';

import { Segment } from 'semantic-ui-react';


/**
 * The color box is not to be confused with the ChronoxBox. This is simply a container that puts a background and shadow around its content.
 * The content fits the whole box, and the box fits its parent.
 */

class ChronoxColorBox extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        /* This element will be rendered. The color is a simple border on the top of the box. Raised means that it has a shadow. margin='auto' is important, as it centers the element. */
        /* The element is a compact one, meaning that it takes as little space as possible (i.e. wraps its contents tightly). However, it applies the passed down style, which allows it to be customized. */
        const element = (
            <Segment raised color='orange' compact clearing={true} style={Object.assign({}, this.props.style, {margin: 'auto'})} size={this.props.size}>
                {this.props.children}
            </Segment>
        );

        return (element);
    }
}

export default ChronoxColorBox;
