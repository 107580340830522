import React from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';

/**
 * Handle old links in the application
 * 
 * NOTE: Maybe this should be a HOC instead
 */
class OldLinksWrapper extends React.Component 
{
	constructor(props)
	{
		super(props);
		
		this.state = {
			redirectTo: null,
		};
		
		this.hijackLinks = this.hijackLinks.bind(this);
	}
	
	componentDidUpdate()
    {
        this.hijackLinks();
    }


	componentDidMount()
	{
        this.hijackLinks();
	}

    /**
	 * Finds all links on the loaded page (except for menu links and empty links), and adds an event listener.
	 * When one of these links is clicked, the app.gotoLink method is called with the associated location.
     */
    hijackLinks() {
		const element = ReactDOM.findDOMNode(this);

		$(element).find("a[href]").not('[href=""]').not('a[href^="tel:"]').not('a[href^="mailto:"]').not('.hijacked-link').click(function(event) { // Find all links that are not menu links
			const l = window.location;
            const currentLocation = l.origin + l.pathname + l.search; // Get the full path of the current location
            const linkLocation = event.target.href.split("#")[0];	  // Get the target of the link (minus the hash part, i.e. example.com?ex=test#hash => example.com?ex=test
			const isAction = event.target.classList.length > 0 && Array.prototype.find.apply(event.target.classList, [element => element === "action"]) !== undefined; // If the link has classes, and one of those is "action", isAction is set to true. If, the link has 0 classes or the "action" class is not found, then isAction is set to false.
			const isJS = event.target.href.includes("javascript");

			var url = event.target.href;

			if(!isAction && !isJS && event.target.href !== "" && currentLocation !== linkLocation) {   // Ignore links that are empty (i.e. only UI) or point to the same location that is currently loaded (which are most likely # links used for navigation on the same page)
                console.log("Clicked link: " + event.target.href);
				event.preventDefault();										     // Prevent the target of the link to be loaded
				event.stopPropagation();
				event.stopImmediatePropagation();
        		app.gotoLink(url);
                //self.setState({redirectTo: urlQuery }); // Redirect instead using router (NOTE that the actual redirect happens in the render method)
               
            }
		}).addClass('hijacked-link');
    }
	
	render()
	{
		return this.props.children;
	}
}

export default OldLinksWrapper;
