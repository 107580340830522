import React from 'react';



class View extends React.Component {
    render() {
        


        const element = (
            <div id={this.props.id} className={this.props.style}>
                {this.props.children}
            </div>
        );

        return (element);
    }
}

export default View;