import React from 'react';

import style from 'css/Header.css';

/**
* Props:
*   - Size:
*       -Type: enum
*       -Options: 'small', 'medium', 'large'
* */
class Header extends React.Component {
    render() {
        /* The H1 header style */
        /*const mediumHeaderStyle = {
			border: 'none',
			marginBottom: '3px',
			marginLeft: '8px',
			fontWeight: 'normal',
			fontSize: '1.3em',
			marginTop: '2em',
			color: '#E88341',
        };*/

        let finalStyle = style.medium;

        switch(this.props.size) {
            case 'small':
                finalStyle = style.small;
                break;
            case 'medium':
                finalStyle = style.medium;
                break;
            case 'large':
                finalStyle = style.large;
                break;
            default:
                finalStyle = style.medium;
        }

        /* Render an h1 using the style defined above */
        const element = (
        	<span className={finalStyle}>
            	{this.props.children}
            </span>
        );

        return (element);
    }
}

export default Header;