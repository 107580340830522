import React from 'react';

import View from 'Toolkit/View.jsx';
import OldView from 'Toolkit/OldView.jsx';
import LedgerOverviewBlock from 'Container/LedgerOverviewBlock.jsx';

class PageLedgers extends React.Component {

	render() {

    const element = (
        <View>
            <h2 className="forBox">Aktiva</h2>
            <LedgerOverviewBlock />

            <h2 className="forBox">Avsluta</h2>
            <LedgerOverviewBlock />
        </View>
    );

    const oldViewElement = <OldView mode="ledgers" refresh={false} />;
	return oldViewElement;
  }
}

export default PageLedgers;