import React from 'react';
import PropTypes from 'prop-types';
import stylePropType from 'react-style-proptype'; // eslint-disable-line
import { translate } from 'react-i18next';
import { connect } from 'react-redux';

import { Menu, Dropdown, Sidebar, Icon, Popup } from 'semantic-ui-react';

import i18n from 'i18n'; // eslint-disable-line
import { LanguageChooser } from 'Toolkit/Utils/LanguageChooser'; // eslint-disable-line
import { setLanguageDropdownStatus, sidebarClick } from './Actions';

function logout() {
  app.ui.logout();
}

class ChronoxMenu extends React.Component {
  constructor(props) {
    super(props);

    this.handleLanguageChange = this.handleLanguageChange.bind(this);
    this.getCurrent = this.getCurrent.bind(this);

    this.app = app;
  }

  getCurrent(mode) {
    const path = this.app.getCurrentPath();

    if (mode === 'index') {
      // Get the last part of the url
      const lastPart = path.split('/').pop();
      // If the url ends with "/" or if it ends with "[companyId2]", then it is the index page
      return lastPart === this.app.store.getState().company.id2 || lastPart === '';
    }

    // If the match returns something
    if (path.match(mode) !== null) {
      // It is the current path
      return true;
    }

    // It is not the current path
    return false;
  }

  handleLanguageChange(language) {
    this.props.onLanguageDropdownClose();
    i18n.changeLanguage(language);
  }

  render() {
    const {
      t,
      mobile,
      languageMenuOpen,
      onLanguageDropdownOpen,
      onLanguageDropdownClose,
      visible,
      items,
      style,
    } = this.props;
    const currentLanguage = i18n.language;

    const menuItems = items.map(item => <Menu.Item key={`menuItem-${item.mode}-${item.url}`} name={item.mode} active={this.getCurrent(item.mode)} onClick={() => this.app.gotoLink(item.url)}>{item.title}</Menu.Item>);

    // This element is shown as a dropdown item in the menu.
    // Once clicked, it triggers the popup that contains the language menu.
    const languageTrigger = (
      <Dropdown.Item>
        <Icon name="dropdown" />
        <span style={{ paddingRight: '0.7em' }}>{t('changeLanguage')}</span>
      </Dropdown.Item>
    );

    // This element contains the language chooser component instance.
    // It wraps the language chooser in a popup.
    const languageChooserElement = (
      <Popup
        trigger={languageTrigger}
        flowing
        hoverable
        open={languageMenuOpen}
        position="right center"
        onOpen={onLanguageDropdownOpen}
        onClose={onLanguageDropdownClose}
      >
        <LanguageChooser
          options={[{ countryCode: 'se', languageCode: 'sv' }, { countryCode: 'gb', languageCode: 'en' }]}
          value={currentLanguage}
          onChange={this.handleLanguageChange}
        />
      </Popup>
    );

    // The menu element is shared between both the mobile and the desktop menus.
    // Therefore, it is decomposed as its own component instance.
    const menu = (
      <Dropdown.Menu>
        <Dropdown.Item text={this.app.store.getState().user.name} disabled />
        {/* Only show the language chooser element in development mode */}
        {i18n.shouldChooseLanguage() ? languageChooserElement : null}
        <Dropdown.Item text={t('logOut')} onClick={logout} />
      </Dropdown.Menu>
    );

    // This menu is displayed on larger screen (i.e. desktop and laptop)
    const fixedMenu = (
      <Menu fixed="left" pointing vertical style={style} size="large">
        {menuItems}
        <Dropdown item simple icon="user" open={languageMenuOpen}>
          {menu}
        </Dropdown>
      </Menu>
    );

    // This menu is displayed on smaller screens (i.e. phones and tablets)
    const sidebarMenu = (
      <Sidebar as={Menu} vertical visible={visible} direction="left" animation="overlay" style={style}>
        {menuItems}
        <Dropdown item simple icon="user" pointing="top right" open={languageMenuOpen}>
          {menu}
        </Dropdown>
      </Sidebar>
    );

    return mobile ? sidebarMenu : fixedMenu;
  }
}

ChronoxMenu.defaultProps = {
  style: {},
};

ChronoxMenu.propTypes = {
  t: PropTypes.func.isRequired,
  mobile: PropTypes.bool.isRequired,
  languageMenuOpen: PropTypes.bool.isRequired,
  onLanguageDropdownOpen: PropTypes.func.isRequired,
  onLanguageDropdownClose: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    mode: PropTypes.string,
    url: PropTypes.string,
    title: PropTypes.string,
  })).isRequired,
  style: stylePropType,
  visible: PropTypes.bool.isRequired,
};

const mapStateToProps = state => Object.assign({}, {
  visible: state.sidebarVisible,
  languageMenuOpen: state.languageMenuOpen,
});

const mapDispatchToProps = dispatch => Object.assign({}, {
  onLanguageDropdownOpen: () => dispatch(setLanguageDropdownStatus(true)),
  onLanguageDropdownClose: () => dispatch(setLanguageDropdownStatus(false)),
  onButtonClick: () => dispatch(sidebarClick()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(translate()(ChronoxMenu));
