import React from 'react';
import { HTMLDocumentComponent } from 'Toolkit/OldComponent';

/**
 * App Component: List/Table of all the reports for the user to submit
 *
 * Props: NONE
 */
class App_ReportsToSubmitTable extends HTMLDocumentComponent {
  componentNamespace() {
    return 'Client.AdminWebV2.Components';
  }
}

export default App_ReportsToSubmitTable;
