import React from 'react';
import OldView from 'Toolkit/OldView.jsx';

class PageExport extends React.Component {
    render() {
        const target = this.props.match.params.target;
        const job = this.props.match.params.job;

        return <OldView mode="exportTarget" target={target} job={job} refresh={false}/>;
    }
}

export default PageExport;
