exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@charset \"UTF-8\";\n\n/**\n * This css document contains the necessary style for the login page of the Chronox app version 2.\n */\n\n.vmazns030PG7k9BEO8Xw9 {\n    box-shadow: 0 2px 5px rgba(0,0,0,0.2);\n    overflow-x: auto;\n    max-width: 100%;\n    margin: auto;\n    padding: 0;\n    border-radius: 0.3em;\n}", ""]);

// exports
exports.locals = {
	"overFlowBox": "vmazns030PG7k9BEO8Xw9"
};