import React from 'react';
import PropTypes from 'prop-types';
import OldView from 'Toolkit/OldView';


/**
 * Page: Timesheet Report editing (for employee)
 * 
 * Props:
 * - period: Date identifying the timesheet (first day of period) "YYYY-MM-DD"
 */
export default function PageReport(props) {
  const { period } = props.match.params;

  sessionStorage.goToAfterPost = `report/timesheets/${period}`;

  return <div className="wrapper-no-overflow" style={{ overflow: 'auto', height: '100%', paddingBottom: '400px' }}><OldView mode="report" period={period} refresh={false} /></div>;
}

PageReport.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      period: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
