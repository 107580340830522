import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';

class QueryParser extends React.Component {

    render() {
        var path = this.props.path;
        path = window.location.search.substring(1);

        
        /**
         * @todo Rewrite this to parse the query string and then use switch/case
         */      
        if(path.match(/mode=/)) { // If the mode query parameter exists
            var to = "";
            var removeList = ["mode"];

            if(path.match(/mode=index/)) {
                to += "";
            }
            else if (path.match(/mode=reports/)) {
                let period = this._getQueryVariable("period", path);
                to = "reports" + (period ? "/" + period : "");
                removeList.push("period");
            }
            else if (path.match(/mode=report\b/) && !path.match(/mode=reports/)) {
                if(path.match(/period=/)) {
                    let period = this._getQueryVariable("period", path);
                    to = "report/timesheets/" + period;
                    removeList.push("period");
                }
                else if(path.match(/order=/)) {
                    to = "report/workreports/order/" + this._getQueryVariable("order", path);
                    removeList.push("order");
                    if(path.match(/day=/)) {
                        to += "/day/" + this._getQueryVariable("day", path);
                        removeList.push("day");
                    }
                } 
            }
            else if (path.match(/mode=view/)) {
                const employee = this._getQueryVariable("employee", path);
                const timeSheets = "timesheets/" + this._getQueryVariable("period", path) + (employee ? "/" + employee : "");
                const order = this._getQueryVariable("order", path);
                const workReports = "workreports/" + order;

                to = "reports/" + (order ? workReports : timeSheets);
                removeList.push("period", "employee", "order");
            }
            else if (path.match(/mode=ledgers/)) {
                to = "ledgers";
            }
            else if (path.match(/mode=ledger/) && !path.match(/mode=ledgers/)) {
                const ledger = this._getQueryVariable("ledger", path);
                removeList.push("ledger");

                if (path.match(/mode=ledgerSettings/)) {
                    to = "ledgers/" + ledger + "/settings";
                    removeList.push("urlCompany");
                } else {
                    to = "ledgers/" + ledger;
                }
            }
            else if (path.match(/mode=employees/)) {
                to = "employees";
            }
            else if (path.match(/mode=employeeEdit/)) {
                const employee = this._getQueryVariable("employee", path);
                to = "employees/" + employee;
                removeList.push("employee");
            }
            else if (path.match(/mode=employeeAdd/)) {
                to = "employees/new";
            }
            else if (path.match(/mode=projects/)) {
                to = "projects";
            }
            else if (path.match(/mode=projectEdit/)) {
                const project = this._getQueryVariable("project", path);
                to = "projects/" + project;
                removeList.push("project");
            }
            else if (path.match(/mode=users/)) {
                to = "users";
            }
            else if (path.match(/mode=userEdit/)) {
                const user = this._getQueryVariable("user", path);
                to = "users/" + user;
                removeList.push("user");
            }
            else if (path.match(/mode=userAdd/)) {
                //const user = this._getQueryVariable("user", path);
                to = "users/new";
                removeList.push("user");
            }
            else if (path.match(/mode=tools/)) {
                to = "tools";
            }
            else if (path.match(/mode=exportTarget/)) {
                const target = this._getQueryVariable("target", path);
                const job = this._getQueryVariable("job", path);

                to = "tools/exports/" + target + "/" + job;
                removeList.push("target", "job");
            }
            else if (path.match(/mode=settings/)) {
                to = "settings";

                if (path.match(/mode=settingsShowConfig/)) {
                    const type = this._getQueryVariable("type", path).toLowerCase();
                    const orderid = this._getQueryVariable("id", path);

                    to += "/" + type + "s/" + orderid;
                    removeList.push("type", "id");
                }
            } else {
                const mode = this._getQueryVariable("mode", path);
                to += "m/" + mode;
            }

            var queryList = "?" + this._getFilteredQueryList(removeList, path || "");

            var newUrl = "/" + to + queryList;
            if(this.props.push) {                                   // If the push flag is set
            	console.log("QueryParser pushing new url: " + newUrl);
                this.props.history.push(newUrl); // Push the path, which adds it to the history
                return null;                                        // Render nothing
            } else {                                                // If the push flag is not set
            	console.log("QueryParser replacing new url: " + newUrl);
                return <Redirect to={newUrl}/>;  // Do a complete redirect, which replaces the current path
            }
        }

        return null; // If no mode query parameter exists, render nothing
    }

    _getQueryVariable(variable, query) {
        query = query ? query : window.location.search.substring(1);
        var vars = query.split('&');
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');
            if (decodeURIComponent(pair[0]) == variable) {
                return decodeURIComponent(pair[1]);
            }
        }

        return null;
    }

    /**
     * Removes the desired variables from the passed in query.
     *
     * @param variables
     * @param query
     * @returns a string
     */
    _getFilteredQueryList(variables, query) {
        var result = query;
        
        if(Array.isArray(variables)) {                          // If the variables to remove
            result = query.split('&').filter(function(param) {  // Divide the query string into a list of individual parameters
                for(const i in variables) {                     // For each query variable i
                    if(param.match(new RegExp(variables[i]))) { // If the variable matches any of the parameters in the query string
                        return false;                           // Exclude it from the result
                    }
                }
                return true;                                    // If the variable does not match any parameter in the query string, keep it
            }).join('&');                                       // Make the new list of filtered variables into a proper string ex. variable1=1&variable2=2 ...
        } else if(typeof variables === 'string') {                                                    // If only one parameter (i.e. a string) is supplied
            result = query.split('&').filter(param => !param.match(new RegExp(variables))).join('&'); // Remove that parameter from the query list
        }

        return result;
    }
}

export default withRouter(QueryParser);