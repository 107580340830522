import React from 'react';

import OldView from 'Toolkit/OldView.jsx';

class PageTimereport extends React.Component {
    render() {
        const period = this.props.match.params.period;
        const employee = this.props.match.params.employee;

        return <OldView mode="view" period={period} employee={employee} refresh={false} />;
    }
}

export default PageTimereport;
