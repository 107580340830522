import React from 'react';

import { Grid } from 'semantic-ui-react';

import ChronoxBoxRow from './ChronoxBoxRow';

import css from 'css/ChronoxBox.css';

/**
 * A ChronoxBox is like a grid. It prefers to have ChronoxBoxRow's (ChronoxBox.Row) as children.
 * Each row will take up approximately 75% of the width.
 */

class ChronoxBox extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        /* The element that will be rendered. It will simply take the passed in children (ChronoxBoxRow) and put the inside of a grid. */
        const element = (
            <Grid columns={1} centered verticalAlign='middle' className={css.grid + " " + (this.props.full ? css.full : "")}>
                {this.props.children}
            </Grid>
        );

        return (element);
    }
}

ChronoxBox.defaultProps = {
	full: false,			// If the box should take as much space as possible
};

/** This member allows you to access the ChronoxBoxRow without having to explicitly import the module (it gets imported when you import the ChronoxBox) */
ChronoxBox.Row = ChronoxBoxRow;

export default ChronoxBox;
