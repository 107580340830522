exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@charset \"UTF-8\";\n\n/**\n * This css document contains the necessary style for the component Toolkit/ChronoxBox of the Chronox react ui toolkit v1.\n */\n \n ._20wgxo1dfIX0L_dtW3uC7z {\n \theight: 100%;\n \twidth: 100%;\n }\n \n ._3kOPbiLKCr_9zm0XXCqQTp {\n \tpadding: 0 !important;\n \tmargin: 0 !important;\n }\n \n .uE9IC_IBP9-SvmY64gGwn {\n \tpadding: 0 !important;\n }\n ", ""]);

// exports
exports.locals = {
	"full": "_20wgxo1dfIX0L_dtW3uC7z",
	"grid": "_3kOPbiLKCr_9zm0XXCqQTp",
	"column": "uE9IC_IBP9-SvmY64gGwn"
};