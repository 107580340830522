import React from 'react';
import OldView from 'Toolkit/OldView.jsx';

class PageUser extends React.Component {
    render() {
        const user = this.props.match.params.user;

        return <OldView mode="userEdit" user={user} refresh={false} />;
    }
}

export default PageUser;