import React from 'react';
import { translate } from 'react-i18next';

import App_ProjectListOld from 'App/ProjectListOld';
import Section from 'Toolkit/Section';

import style from 'css/CurrentProjectsSection.css';

class OldProjectsSection extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { t } = this.props;
        const element = (
        	<Section title={t('OldProjectsSection.title')}>
            	<App_ProjectListOld className={style.overFlowBox}></App_ProjectListOld>
        	</Section>
        );

        return element;
    }
}

export default translate('Container')(OldProjectsSection);
