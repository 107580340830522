/* eslint no-console: off, class-methods-use-this: off */

// Checks if the app is in production
function isProduction() {
  return process.env.NODE_ENV === 'production';
}

// Sends a message to a remote error service
function sendToRemote(message) {
  // Send stringified message to sentry
  Raven.captureMessage(JSON.stringify(message));
}

// Sets the user context
function setUser(user) {
  // Set the raven user context.
  // This is useful for tracking user specific problems.
  Raven.setUserContext(user);
}

/**
 * This class handles all types of logging.
 * It intercepts the log when in production, sends error to
 * a error tracking service and cleans the console in production.
 */
export default class Logger {
  /**
   * Sets the user to be used for error logging.
   *
   * @param {object} user
   */
  setUser(user) {
    setUser(user);
  }

  /**
   * Intercepts the console in production, and passes through
   * all messages when in development. This method should be called
   * as early in the app creation as possible.
   */
  interceptConsole() {
    // Create a custom console object (inspired by: https://stackoverflow.com/a/42651389)
    const console = ((originalConsole) => {
      // If the app is in production, clear the console after one second.
      // This is useful for protecting the apps internal logic, and to make
      // the production version more transparent.
      if (isProduction()) {
        setTimeout(() => originalConsole.clear(), 1000);
      }

      // Return the object that is to be used as the new window.console object.
      // The object simply contains the functions that the original window.console object,
      // contains.
      return {

        // Replaces the console.log function.
        log: (...args) => {
          // Simply ignore this method if the app is in production.
          // Otherwise just pass through all arguments to the original
          // console.log.
          if (!isProduction()) {
            originalConsole.log(...args);
          }
        },

        // Replaces the console.info function.
        info: (...args) => {
          // Simply ignore this method if the app is in production.
          // Otherwise just pass through all arguments to the original
          // console.info.
          if (!isProduction()) {
            originalConsole.info(...args);
          }
        },

        // Replaces the console.warn function.
        warn: (...args) => {
          // If the app is in production, send the console messages to,
          // the error logging service instead.
          if (isProduction()) {
            const messages = { type: 'console.warn', arguments: [...args] };
            sendToRemote(messages);
          // If the the app is in development, simply pass through all arguments
          // to the original console.warn.
          } else {
            originalConsole.warn(...args);
          }
        },

        // Replaces the console.error function.
        error: (...args) => {
          // If the app is in production, send the console messages to,
          // the error logging service instead.
          if (isProduction()) {
            const messages = { type: 'console.error', arguments: [...args] };
            sendToRemote(messages);
          // If the the app is in development, simply pass through all arguments
          // to the original console.error.
          } else {
            originalConsole.error(...args);
          }
        },
      };
    })(window.console);

    // Replace the standard console object with the custom one
    window.console = console;
  }
}
