import React from 'react';
import StylePropType from 'react-style-proptype';
import { Image } from 'semantic-ui-react';

/**
 * The company logo can be used in order to insert the company logo of the currently logged in user.
 */
export default function CompanyLogo(props) {
  const state = app.store.getState();
  const name = state.company ? state.company.name : '';
  const { style } = props;

  if (state.company) {
    return (
      <Image src={state.company.logoUrl} alt={state.company.name} style={style} />
    );
  }

  return (
    <span className="nologo">{name}</span>
  );
}

CompanyLogo.defaultProps = {
  style: {},
};

CompanyLogo.propTypes = {
  style: StylePropType,
};
